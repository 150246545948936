import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'markdown-to-jsx';
import { ButtonV2 } from '@getgrover/ui';
import { Text } from '@/components/Text';
import { Group } from '@/components/Group';
import { Offset } from '@/components/Offset';
import { tk } from '@/i18n/translationKeys';
import { ScreenCommon, ScreenKey } from '../../types';
import { Actions, Height, ExternalLink } from '../../AddPaymentMethodPage.styles';
import { useNavigate } from 'react-router-dom';
import { useApplicationData } from '@/providers/applicationData';
import { CardErrorIcon } from '@/icons/CardErrorIcon';
import { useScreenMatch } from '@/hooks/useScreenMatch';
import { translationsMap } from './errorMapping';
import { TransactionErrorCode } from '@/generated/types';
import { FeatureName, LogPrefix, logger } from '@/logger';
import { trackAddPaymentMethodErrorEvent } from '@/analytics';
import { useTheme } from 'styled-components';

const NO_ERROR_CODE_RECEIVED = 'no error code';

export const AddPaymentMethodErrorScreen = ({ setScreen, currentScreen }: ScreenCommon) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { storeCode, userId, platform, rawUseragent, routeBuilder } = useApplicationData();
  const { isMobile } = useScreenMatch();

  const errorCode = currentScreen.data.customErrorCode || TransactionErrorCode.OPERATION_FAILED;
  const { title, text } =
    translationsMap[errorCode as TransactionErrorCode] ||
    translationsMap[TransactionErrorCode.OPERATION_FAILED];

  const handleTryAgain = () => {
    navigate(routeBuilder.buildAddPaymentMethodRoute());
    setScreen(ScreenKey.paymentMethodOptions, {});
  };

  useEffect(() => {
    logger.error(LogPrefix.Failure, {
      feature: FeatureName.AddPaymentMethod,
      error: {
        status: currentScreen.data.customErrorCode || NO_ERROR_CODE_RECEIVED,
        message: title,
      },
    });

    trackAddPaymentMethodErrorEvent({
      userId,
      store: storeCode,
      errorStatus: currentScreen.data.customErrorCode || NO_ERROR_CODE_RECEIVED,
      errorMessage: title,
      platform,
      rawUseragent,
    });
  }, []);

  return (
    <Height mobile={isMobile} data-testid="add-pm-error-screen">
      <Group vertical gap={8} mobileGap={8}>
        <CardErrorIcon />

        <Text data-testid="error-title" typography="Headline" color={theme.colors.text.darkerGrey}>
          {t(title)}
        </Text>

        <Offset bottom={20}>
          <Text
            data-testid="error-text"
            typography="Paragraph"
            color={theme.colors.text.darkerGrey}
          >
            <Markdown
              options={{
                overrides: {
                  a: {
                    component: ExternalLink,
                  },
                },
              }}
            >
              {t(text)}
            </Markdown>
          </Text>
        </Offset>

        <Actions mobile={isMobile}>
          <ButtonV2 fullWidth onClick={handleTryAgain}>
            {t(tk.addPaymentMethodErrorButtonText)}
          </ButtonV2>
        </Actions>
      </Group>
    </Height>
  );
};
